import { tsSdkEventName } from "@/constant/enumtsskd"
import tsTrackingSdkInstance from "@/utils/tssdk"
const stSdkSetEvent = tsTrackingSdkInstance.setEvent
const eventName = tsSdkEventName.ELEMENTCLICK

export const useNewHomeTsSdk = () => {
  const _pointNewHomeSignUpClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "sign_in",
      element_type: "button",
      element_position: "nav_bar"
    })
  }

  const _pointNewHomeNavToDownloadClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "download",
      element_type: "button",
      element_position: "nav_bar"
    })
  }

  const _pointNewHomeHeroClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "try_evoto_for_free",
      element_type: "button",
      element_position: "hero_section"
    })
  }

  const _pointNewHomeSimplifyLifeClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "try_evoto_for_free",
      element_type: "button",
      element_position: "section_2"
    })
  }

  const _pointNewHomeFeatureClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "try_evoto_for_free",
      element_type: "button",
      element_position: "section_5"
    })
  }

  const _pointNewHomeEnjoyClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "try_evoto_for_free",
      element_type: "button",
      element_position: "section_6"
    })
  }

  const _pointNewHomeEndingClick = () => {
    stSdkSetEvent(eventName, {
      element_name: "try_evoto_for_free",
      element_type: "button",
      element_position: "cta_section"
    })
  }

  return {
    _pointNewHomeSignUpClick,
    _pointNewHomeNavToDownloadClick,
    _pointNewHomeHeroClick,
    _pointNewHomeSimplifyLifeClick,
    _pointNewHomeFeatureClick,
    _pointNewHomeEnjoyClick,
    _pointNewHomeEndingClick
  }
}
